<template>
  <div class="dot-container">
    <div class="dot-bouncer"></div>
    <div class="step" id="s1"></div>
    <div class="step" id="s2"></div>
    <div class="step" id="s3"></div>
  </div>
</template>
<script>
export default {
  name: 'DotBouncer',
};
</script>
