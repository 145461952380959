export function initFonts() {
  if (!('fonts' in document)) {
    console.log('document.fonts NOT supported');
    return;
  }

  const signikaFace = new FontFace(
    'Signika',
    'url(https://fonts.gstatic.com/s/signika/v8/vEFR2_JTCgwQ5ejvG1EmBg.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  );

  signikaFace.load();
  signikaFace.loaded.then(() => {
    console.log('signikaFace loaded');
  });
  document.fonts.add(signikaFace);

  document.fonts.ready.then(() => {
    console.log('document.fonts.ready');
    document.body.classList += ' fonts-loaded';
  });
}

export function syncDarkTheme(bool) {
  const classes = window.document.body.className.split(' ');
  if (!bool && classes.includes('dark')) {
    window.document.body.className = classes
      .filter(c => c !== 'dark')
      .join(' ');
  } else if (bool && !classes.includes('dark')) {
    window.document.body.className = [...classes, 'dark'].join(' ');
  }
}
