export const currentData = state => {
  const wanted = `restaurants_${state.prefs.campus}`;
  const dNow = state.jsonData[state.current.dayName];
  if (wanted in dNow) {
    return dNow[wanted];
  } else if ('error' in dNow) {
    return dNow;
  } else {
    return {};
  }
};

export const currentOrder = (state, getters) => {
  const campus = state.prefs.campus;

  const allKeys = Object.keys(getters.currentData);

  if (campus in state.prefs.orders) {
    const arr = state.prefs.orders[campus];

    if (arr.length > 0) {
      const missing = allKeys.filter(key => arr.indexOf(key) === -1);
      return arr.concat(missing);
    }
  }

  return allKeys;
};

export const isResHidden = state => res => {
  const hiddens = state.prefs.hiddens[state.prefs.campus];
  if (!hiddens) return false;
  return hiddens.indexOf(res) !== -1;
};

export const currentHiddenKeys = state => {
  return state.prefs.hiddens[state.prefs.campus] || [];
};

export const currentShowKeys = (state, getters) => {
  const hiddens = getters.currentHiddenKeys;
  return getters.currentOrder.filter(key => !hiddens.includes(key));
};
