<i18n>
{
  "fi": {
    "closed": "Ravintola kiinni tänään",
    "outsideCampus": "Ravintola sijaitsee kampuksen ulkopuolella"
  },
  "en": {
    "closed": "Restaurant closed today",
    "outsideCampus": "Restaurant is not located in campus area"
  }
}
</i18n>

<template>
  <div class="col-md-4 restaurant-outer-container">
    <div class="d-flex name-hours-row">
      <div class="restaurant-name">
        <a
          :class="{ hidden: hidden }"
          :href="restaurant.rurli"
          class=""
          target="_BLANK"
          >{{ restaurant.restaurant }}</a
        >
      </div>

      <div v-if="!hidden" class="ml-auto hours-outer">
        {{ restaurant.eating_hours }}
        <template v-if="restaurant.open_hours">
          ({{ restaurant.open_hours }})</template
        >
      </div>
    </div>

    <template v-if="!hidden">
      <div v-if="restaurant.out_of_campus" class="out-of-campus">
        {{ $t('outsideCampus') }}
      </div>
      <template v-if="restaurant.open_today">
        <template v-if="restaurant.lisainfo">
          <div class="lisainfoboksi_yla">
            {{ restaurant.lisainfo }}
          </div>
        </template>
        <div class="meal_box">
          <!-- eslint-disable-next-line -->
          <Meal v-for="(meal, index) in meals" :key="`meal-${index}`" :meal="meal" :prefs="prefs" />
        </div>
      </template>

      <template v-else>
        <div class="mealit">
          <div class="">
            <template v-if="restaurant.lisainfo">
              {{ restaurant.lisainfo }}
            </template>
            <template v-else>{{ $t('closed') }}</template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import Meal from './Meal.vue';
import { FILTER_TYPES } from '../store';

export default {
  name: 'Restaurant',
  props: ['restaurant'],
  components: {
    Meal,
  },
  computed: {
    meals() {
      const filters = this.prefs.dietFilters;
      const AND = this.prefs.dietFilterLogicalAnd;
      const meals =
        this.$root.$i18n.locale === 'fi'
          ? this.restaurant.meals
          : this.restaurant.meals_en;
      return meals
        .filter(meal => meal.mo.length > 0)
        .map(meal => ({
          ...meal,
          mo: [...meal.mo],
        }))
        .map(meal => {
          if (filters.length === 0) return meal;

          return {
            ...meal,
            mo: meal.mo
              .map(mo => ({ ...mo }))
              .filter(part => {
                const notFound = filters.filter(
                  f =>
                    !part.mpd
                      .split(',')
                      .map(d => d.trim())
                      .includes(f)
                );
                const unsatisfiedFiltersFound = AND
                  ? notFound.length !== 0 // some missing
                  : notFound.length === filters.length; // all missing

                switch (this.prefs.dietFilterType) {
                  case FILTER_TYPES.HIGH:
                    part.high = !unsatisfiedFiltersFound;
                    return true;

                  case FILTER_TYPES.ONLY:
                    return !unsatisfiedFiltersFound;

                  case FILTER_TYPES.HIDE:
                    return unsatisfiedFiltersFound;

                  default:
                    return true;
                }
              }),
          };
        })
        .filter(meal => meal.mo.length > 0);
    },
    prefs() {
      return this.$store.state.prefs;
    },
    hidden() {
      return this.$store.getters.isResHidden(this.restaurant.res);
    },
    showYr() {
      return this.$store.state.current.week === 48;
    },
  },
};
</script>
<style scoped>
.hidden {
  text-decoration: line-through;
  font-style: italic;
}

.restaurant-outer-container {
  margin-bottom: 2rem;
  transition: all 1s;
}

@media screen and (max-width: 410px) {
  .restaurant-outer-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 374px) {
  .restaurant-outer-container {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (max-width: 359px) {
  .restaurant-outer-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.name-hours-row {
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
}

.restaurant-name {
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.2rem;
}

.hours-outer {
  font-size: 0.8rem;
  align-self: center;
}

.out-of-campus {
  font-size: 0.9rem;
  font-style: italic;
  padding: 0.2rem 1rem;
}
.yr-astyt {
  font-size: 13px;
}
</style>
