import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { TrackJS } from 'trackjs';

import store from './store/index.js';
import App from './comp/App.vue';
import { initFonts, syncDarkTheme } from './utils.js';
import { initSW } from './sw.js';

Vue.use(VueI18n);

if (window.location.hostname !== 'localhost') {
  window.TrackJS &&
    TrackJS.install({
      token: '04ec886748e4440fb23bbb6d6a014583',
      application: 'unisafka-trackjs',
      console: { enabled: false },
    });
}

syncDarkTheme(store.state.prefs.dark);

// eslint-disable-next-line
console.log(BUNDLEMODE);

initFonts();

const refresh = setDate => {
  store.commit('INVALIDATE_DATA', setDate);
  store.dispatch('GET_CURRENT_DAY');
  store.dispatch('FLUSH_CACHE');
  setTimeout(() => store.dispatch('GET_ALL_DAYS'), 150);
  setTimeout(() => store.dispatch('CHECK_SUNDAY'), 1000);
};

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    refresh();
  }
});

const url = window.location.href.split('/');
['tty', 'tay', 'tays', 'tamk'].forEach(k => {
  if (url.includes(k)) {
    store.commit('CHOOSE_CAMPUS', k);
  }
});

refresh(true);

const i18n = new VueI18n({
  locale: store.state.prefs.lang || 'fi',
  messages: {
    fi: {
      ma: 'ma',
      ti: 'ti',
      ke: 'ke',
      to: 'to',
      pe: 'pe',
      la: 'la',
      su: 'su',
    },
    en: {
      ma: 'Mon',
      ti: 'Tue',
      ke: 'Wed',
      to: 'Thu',
      pe: 'Fri',
      la: 'Sat',
      su: 'Sun',
    },
  },
});

new Vue({
  el: '#APP',
  render: h => h(App),
  store: store,
  i18n,
});

import(/* webpackChunkName: 'doorbell' */ './doorbell.js').then(module => {
  const def = module.default;
  def.initDoorbell(store.state.mobile, store.state.prefs.lang);
});

initSW();

store.commit('REVERT_PREFS');
store.commit('INCREASE_VISIT_COUNT');
