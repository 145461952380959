<template>
  <div class="dark-picker mt-3 mr-3" :class="{ 'mobile-dark-picker': mobile }">
    <img
      @click="toggleLang"
      width="20"
      class="mr-2"
      :src="require(`../assets/${lang === 'fi' ? 'en' : 'fi'}-flag.png`)"
    />
    <template v-if="dark">
      <span @click="toggle" v-html="sun" style="fill: white"></span>
    </template>
    <template v-else>
      <span @click="toggle" v-html="moon" style="fill: black"></span>
    </template>
  </div>
</template>
<script>
import { sun, moon } from '../icons';
export default {
  name: 'Dark',
  data() {
    return {
      sun,
      moon,
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    dark() {
      return this.$store.state.prefs.dark;
    },
    lang() {
      return this.$store.state.prefs.lang;
    },
  },
  methods: {
    toggle() {
      this.$store.commit('CHOOSE_SETTING', 'dark');
    },
    toggleLang() {
      this.$store.commit(
        'SET_LANGUAGE',
        this.$store.state.prefs.lang === 'fi' ? 'en' : 'fi'
      );
    },
  },
};
</script>
<style>
.dark-picker {
  cursor: pointer;
}
.mobile-dark-picker {
  position: absolute;
  top: 12px;
  right: 0px;
}
</style>
