/* global ga */
import store from './store/index';

const gaFound = () => window.ga && ga.create;

const customGa = (c, a, l) => {
  setTimeout(() => {
    if (gaFound()) {
      ga('send', 'event', {
        eventCategory: c,
        eventAction: a,
        eventLabel: l,
      });
    }
  }, 2000);
};

export const gaCampusDay = () =>
  customGa('piirto', 'kampus', store.state.prefs.campus);

export function gaVueFire(prefs) {
  gaCampusDay();
  const d = new Date().getDate();
  if (window.__gaVueFireD !== d) {
    window.__gaVueFireD = d;
    setTimeout(() => {
      gaAppVersion();
      gaBundleMode();
      gaPrefs(prefs);
    }, 2500);
  }
}

export function gaFirstTime() {
  customGa('piirto', 'ekakerta', store.state.prefs.campus);
}

export function gaOnError(funk, msg) {
  customGa('error', funk, msg);
}

export function gaButton(btn) {
  customGa('piirto', 'toiminnot', btn);
}

export function gaCache(truth) {
  customGa('stats', 'cacheHit', truth);
}

export function gaCacheBusted(newBit) {
  customGa('stats', 'cacheBusted', `cb-${newBit}`);
}

export function gaFetchAnother(message) {
  customGa('stats', 'upgradeVersion', message);
}

export function gaAppVersion() {
  customGa(
    'stats',
    'version',
    `${store.state.current.week}::${store.state.jsonData.version}`
  );
}

function gaBundleMode() {
  // eslint-disable-next-line
  customGa('stats', 'bundle', BUNDLEMODE);
}

const sendPref = (action, label) => {
  customGa('prefs', action, label);
};

function gaPrefs(prefs) {
  if (prefs.lang === 'en') {
    sendPref('lang', 'en');
  }
  if (prefs.dark) {
    sendPref('theme', 'dark');
  }
  if (!prefs.showGroups) {
    sendPref('hideGroups', true);
  }
  if (!prefs.showDiets) {
    sendPref('hideDiets', true);
  }
  if (!prefs.showPrices) {
    sendPref('hidePrices', true);
  }
  if (prefs.dietFilters.length !== 0) {
    sendPref('dietFilters', true);
  }
}

export function gaPerfNow(now) {
  if (gaFound()) {
    ga('send', {
      hitType: 'timing',
      timingCategory: 'stats',
      timingVar: 'vue-created',
      timingValue: now,
    });
  }
}
