import Vue from 'vue';
import { gaCacheBusted, gaAppVersion } from '../ga.js';

export const GET_CURRENT_DAY = ({ state, commit }) => {
  GET_SINGLE_DAY({ state, commit }, state.current.dayName);
};

export const GET_ALL_DAYS = ({ dispatch }, skipSunday) => {
  ['ma', 'ti', 'ke', 'to', 'pe', 'la', 'su'].forEach((day, index) => {
    if (day === 'su' && skipSunday) return;
    setTimeout(() => {
      dispatch('GET_SINGLE_DAY', day);
    }, 100 * index);
  });
};

const customCampusData = allData => {
  return {
    ...allData['restaurants_tty'],
    ...allData['restaurants_tay'],
    ...allData['restaurants_tays'],
    ...allData['restaurants_tamk'],
  };
};

export const GET_SINGLE_DAY = ({ state, commit }, day) => {
  const week = state.current.week;
  const version = state.jsonData.version;
  const year = new Date().getFullYear();
  const dataURL = `/static/json/${year}/${week}/${version}/${day}.json`;

  const currentData = state.jsonData[day];
  if (currentData.week === week && currentData.version === version) {
    return Promise.resolve();
  }

  if (dataURL in state.concurrent) {
    return;
  }

  state.concurrent[dataURL] = true;
  return fetch(dataURL)
    .then(res => res.json())
    .then(resData => {
      Object.keys(resData).forEach(resDataKey => {
        if (resDataKey.indexOf('restaurants_') === 0) {
          Object.keys(resData[resDataKey]).forEach(resKey => {
            resData[resDataKey][resKey]['res'] = resKey;
          });
        }
      });

      delete state.concurrent[dataURL];

      // if NOT this, then cache was busted when the request was happening
      if (version === state.jsonData.version) {
        state.jsonData.week = state.current.week;
        state.jsonData.year = state.current.year;
        resData.week = week;
        resData.version = version;
        resData['restaurants_oma'] = customCampusData(resData);
        Vue.set(state.jsonData, day, resData);
      }
    })
    .catch(() => {
      commit('SET_DAY_TO_ERR', day);
    });
};

export const CHOOSE_DAY = ({ state, commit, dispatch }, day) => {
  commit('CHOOSE_DAY', day);

  if (Object.keys(state.jsonData[day]).length < 2) {
    dispatch('GET_SINGLE_DAY', day);
  }
};

const fetchVersion = (week, year) => {
  const remote = `/static/json/${year}/${week}/v.json`;
  return fetch(remote, {
    headers: {
      'cache-control': 'no-cache',
      pragma: 'no-cache',
    },
  })
    .then(r => r.json())
    .then(v => parseInt(v['v']));
};

export const FLUSH_CACHE = ({ state, dispatch, commit }) => {
  const { year, week } = state.current;

  return fetchVersion(week, year)
    .then(newVersion => {
      const oldVersion = state.jsonData.version;
      console.log(`${week}: ${oldVersion} => ${newVersion}`);
      if (!!newVersion && parseInt(newVersion) !== parseInt(oldVersion)) {
        commit('BUST_CACHE_VERSION', newVersion);
        dispatch('GET_ALL_DAYS');

        gaCacheBusted(`${year}-${week}-${newVersion}`);
        gaAppVersion(`${year}-${week}`, newVersion);
      }
    })
    .catch(err => {
      console.log('fetchVersion::catch');
      console.log(err);
    });
};

export const CHECK_SUNDAY = ({ state, dispatch }) => {
  const d = new Date();
  const nextWeek = d.getWeek() + 1;
  if (d.isSunday()) {
    fetchVersion(nextWeek, d.getFullYear())
      .then(nextVersion => {
        if (
          nextVersion &&
          (state.jsonData.version !== nextVersion ||
            state.current.week !== nextWeek)
        ) {
          console.log('Bumb week...');
          state.jsonData.version = nextVersion;
          state.current.week = d.getWeek() + 1;
          state.days.forEach(day => {
            if (day === 'su') return;
            Vue.set(state.jsonData, day, {});
          });
          dispatch('GET_ALL_DAYS', true);
          state.showSundayBanner = true;
        }
      })
      .catch(err => {
        console.log('TMRS V NOT AVAILABLE', err);
      });
  }
};
