<template>
  <div class="meal">
    <div class="d-flex title-price">
      <div class="meal-group" v-if="prefs.showGroups">
        {{ meal.kok }}
      </div>

      <div class="ml-auto meal-price" v-if="prefs.showPrices">
        {{ meal.price }}
      </div>
    </div>

    <div class="d-flex flex-column">
      <div v-for="part in meal.mo" :class="{ 'highlight-meal': part.high }">
        <div :key="part.mpn" class="meal-name">
          {{ part.mpn }}
        </div>
        <div :key="part.mpn + 'd'" v-if="prefs.showDiets" class="meal-diet">
          {{ part.mpd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ok } from '../icons.js';
import { FILTER_TYPES } from '../store/index';

export default {
  name: 'Meal',
  props: ['meal', 'prefs'],
  data() {
    return {
      iconDietOk: ok,
    };
  },
  computed: {
    dietFiltersInUse() {
      return (
        !(this.prefs.dietFilters.length === 0) &&
        this.prefs.dietFilterType === FILTER_TYPES.HIGH
      );
    },
  },
};
</script>
<style scoped>
.meal {
  border-bottom: 1px solid #d1d0d0;
  padding: 0.5rem 0;
}

.meal:first-child {
  padding-top: 0;
}

.meal:last-child {
  border-bottom: none;
}

.highlight-meal {
  background: var(--highlight-meal);
  margin-left: -5px;
  padding-left: 5px;
}

.meal-name {
  font-size: 0.95rem;
}

.meal-diet {
  color: var(--extra-text);
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
  font-size: 0.7rem;
  line-height: 1.25;
}

.title-price {
  flex-wrap: wrap;
}

.meal-price {
  color: var(--extra-text);
  text-align: right;
  font-size: 0.7rem;
}

.meal-group {
  color: var(--extra-text);
  text-transform: uppercase;
  font-size: 0.7rem;
}
</style>
