<template>
  <div class="d-flex start-screen-container justify-content-center">
    <div class="p-2 actual-container">
      <h3>
        <span class="tuni-t">(T)</span>Unisafka
        <br />
        <span class="h3-tampere"
          >Tampereen korkeakouluyhteisön ruokalistat
        </span>
      </h3>
      <span class="note">(vanhat TTY, TAY, TAYS ja TAMK)</span>
      <p class="mt-4">Aloita valitsemalla kampus!</p>
      <div class="buttons">
        <button
          v-for="campus in ['TTY', 'TAY', 'TAYS', 'TAMK', 'OMA']"
          :key="campus"
          @click="choose(campus)"
          class="btn btn-sm btn-success"
        >
          {{ getCampusName(campus) }}
        </button>
      </div>
      <p class="links">
        Kampukset löytyvät myös suoraan osoitteista
        <a href="/tty/">unisafka.fi/tty</a>,
        <a href="/tay/">unisafka.fi/tay</a>,
        <a href="/tays/">unisafka.fi/tays</a> ja
        <a href="/tamk/">unisafka.fi/tamk</a>
      </p>
    </div>
  </div>
</template>
<script>
import { gaFirstTime } from '../ga';

export default {
  name: 'StartScreen',
  methods: {
    choose(campus) {
      this.$store.commit('CHOOSE_CAMPUS', campus);
      gaFirstTime();
    },
    getCampusName(campus) {
      if (campus === 'TTY') {
        return 'Hervanta';
      } else if (campus === 'TAY') {
        return 'Keskusta';
      }
      return campus;
    },
  },
};
</script>
<style scoped>
.start-screen-container {
  text-align: center;
  flex: 1;
  align-items: center;
}

.h3-tampere {
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .start-screen-container {
    align-items: start;
    padding-top: 10%;
  }
}

.buttons .btn {
  margin: 0.25rem;
}

.tuni-t {
  font-size: 10px;
}

.note {
  font-size: 10px;
}

.links {
  margin-top: 40px;
  font-size: 14px;
}
</style>
