import Vue from 'vue';
import Vuex from 'vuex';
import PersistedState from 'vuex-persistedstate';

import * as actions from './actions.js';
import * as getters from './getters.js';
import * as mutations from './mutations.js';

Vue.use(Vuex);

Date.prototype.getWeek = function() {
  const dt = this;
  var tdt = new Date(dt.valueOf());
  var dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  var firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

Date.prototype.isSunday = function() {
  return new Date().getDay() === 0;
};

const days = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'];
const w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  WIDTH = w.innerWidth || e.clientWidth || g.clientWidth;

export const FILTER_TYPES = {
  HIGH: 'high',
  ONLY: 'only',
  HIDE: 'hide',
};

export default new Vuex.Store({
  state: {
    mobile: WIDTH < 990,
    showSundayBanner: false,
    days,
    campuses: ['TTY', 'TAY', 'TAYS', 'TAMK', 'OMA'],
    current: {
      dayName: null,
      week: null,
      year: null,
    },

    diets: ['G', 'L', 'M', 'KASV', 'VEG'],

    dietFilterTypes: [
      { name: FILTER_TYPES.HIGH, text: 'highlight' },
      { name: FILTER_TYPES.ONLY, text: 'showOnly' },
      { name: FILTER_TYPES.HIDE, text: 'hide' },
    ],

    prefs: {
      lang: 'fi',
      campus: null,
      orders: {},
      hiddens: {},
      hideWholeRes: false,
      dietFilters: [],
      dietFilterType: FILTER_TYPES.HIGH,
      dietFilterLogicalAnd: true,
      showGroups: true,
      showDiets: true,
      showPrices: true,
      hideCustomCampusMsg: false,
      hideSettingsMsg: false,
      visitCount: 0,
      dark: false,
      prefsReverted: false,
    },

    concurrent: {},

    jsonData: {
      version: 1,
      week: 1,
      year: new Date().getFullYear(),
      ma: {},
      ti: {},
      ke: {},
      to: {},
      pe: {},
      la: {},
      su: {},
    },
  },

  mutations,
  actions,
  getters,

  plugins: [
    PersistedState({
      paths: ['prefs', 'jsonData'],
    }),
  ],
});
