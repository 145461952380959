import Vue from 'vue';
import { syncDarkTheme } from '../utils';
import { gaButton } from '../ga';

const toggleInArray = (array, thing) => {
  if (array.indexOf(thing) === -1) {
    array.push(thing);
  } else {
    array = array.filter(e => e !== thing);
  }
  return array;
};
export const CHOOSE_CAMPUS = (state, campus) => {
  state.prefs.campus = campus.toLowerCase();
  gaButton('campus');
};

export const CHOOSE_DAY = (state, day) => {
  state.current.dayName = day;
  gaButton('day');
};

export const CHOOSE_DIET = (state, diet) => {
  state.prefs.dietFilters = toggleInArray(
    state.prefs.dietFilters.slice(),
    diet
  );
  gaButton('diet');
};

export const CHOOSE_DIET_FILTER_TYPE = (state, type) => {
  state.prefs.dietFilterType = type;
  gaButton('dietFilterType');
};

export const CHOOSE_DIET_FILTER_LOGICAL_AND = (state, type) => {
  state.prefs.dietFilterLogicalAnd = type;
  gaButton('dietLogicalAnd');
};

export const CHOOSE_SETTING = (state, setting) => {
  state.prefs[setting] = !state.prefs[setting];
  const dark = setting === 'dark';
  if (dark) {
    syncDarkTheme(state.prefs['dark']);
  }
  gaButton(dark ? 'toggleDark' : setting);
};

export const SET_NEW_ORDER = (state, newOrder) => {
  Vue.set(state.prefs.orders, state.prefs.campus, newOrder);
};

export const SET_DAY_TO_ERR = (state, day) => {
  state.jsonData[day].error = true;
};

export const TOGGLE_RES_VISIBILITY = (state, res) => {
  const campus = state.prefs.campus;
  if (!state.prefs.hiddens[campus]) {
    Vue.set(state.prefs.hiddens, campus, []);
  }
  state.prefs.hiddens[campus] = toggleInArray(state.prefs.hiddens[campus], res);
};

export const SET_NEW_HIDDENS_FOR_CAMPUS = (state, hiddens) => {
  if (!state.prefs.hiddens[state.prefs.campus]) {
    Vue.set(state.prefs.hiddens, state.prefs.campus, []);
  }
  state.prefs.hiddens[state.prefs.campus] = hiddens;
};

export const BUST_CACHE_VERSION = (state, newVersion) => {
  state.jsonData.version = parseInt(newVersion);
};

export const INVALIDATE_DATA = (state, setDay) => {
  const d = new Date();
  // not sunday OR sunday and current week is not the following one
  // 2nd case is useful when the user has not visited for over a week
  if (
    !d.isSunday() ||
    (d.isSunday() && state.current.week != d.getWeek() + 1)
  ) {
    state.current.year = d.getFullYear();
    state.current.week = d.getWeek();
  }

  if (setDay) {
    state.current.dayName = state.days[d.getDay()];
  }

  if (state.jsonData.week !== state.current.week) {
    state.jsonData.version = 1;
    state.days.forEach(day => {
      Vue.set(state.jsonData, day, {});
    });
  }
};

export const SET_HIDE_WHOLE_RES = (state, val) => {
  state.prefs.hideWholeRes = val;
};

export const HIDE_CUSTOM_CAMPUS_INFO_MSG = state => {
  state.prefs.hideCustomCampusMsg = true;
};

export const INCREASE_VISIT_COUNT = state => {
  state.prefs.visitCount += 1;
};

export const HIDE_TOOLTIP = (state, tip) => {
  const types = {
    settings: 'hideSettingsMsg',
  };
  state.prefs[types[tip]] = true;
  gaButton(`tooltip:${tip}`);
};

export const SET_LANGUAGE = (state, lang) => {
  state.prefs.lang = lang;
  window.setDoorbellLanguage(lang);
  gaButton('toggleLang');
};

export const REVERT_PREFS = state => {
  const { prefsReverted, visitCount } = state.prefs;
  if (visitCount === 0) {
    state.prefs.prefsReverted = true;
  } else if (visitCount !== 0 && !prefsReverted) {
    state.prefs.prefsReverted = true;
    state.prefs.showDiets = true;
    state.prefs.showPrices = true;
    state.prefs.showGroups = true;
  }
};
